import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { teamInfo } from '../../../Model/home'


function TeamArea() {
  return (
	 <section id="team" className="team-area">
            <div className="container custom-container">
              <div className="row">
                <div className="col-12">
                  <div className="third-section-title text-center mb-2">
                    <h2>MEET <span>OUR</span> TEAM</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <AnimationOnScroll animateIn='animate__rollIn' duration={2} animateOnce={true}>
                  <div className="third-team-item text-center mb-30">
                    <div className="third-team-img">
                      <img src="assets/img/team/1.png" className="mx-auto" alt="lucky elephant nft" />
                    </div>
                    <div className="third-team-content">
                      <div className="main-bg" />
                      <div className="hover-bg" />
                      <h5><a href={teamInfo[0].instagramLink} target="_blank" rel="noreferrer">{teamInfo[0].name}</a></h5>
                      <span>Lead</span>
                    </div>
                  </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <AnimationOnScroll animateIn='animate__rollIn' duration={2} animateOnce={true}>
                  <div className="third-team-item text-center mb-30">
                    <div className="third-team-img">
                      <img src="assets/img/team/2.png" alt="nft lucky elephant club" className="mx-auto"/>
                    </div>
                    <div className="third-team-content">
                      <div className="main-bg" />
                      <div className="hover-bg" />
                      <h5><a href={teamInfo[1].linkedInLink} target="_blank" rel="noreferrer">{teamInfo[1].name}</a></h5>
                      <span>Smart Contract Dev</span>
                    </div>
                  </div>
                  </AnimationOnScroll>

                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <AnimationOnScroll animateIn='animate__rollIn' duration={2} animateOnce={true}>
                  <div className="third-team-item text-center mb-30">
                    <div className="third-team-img">
                      <img src="assets/img/team/3.png" alt="lucky elephant jungleverse nft" className="mx-auto" />
                    </div>
                    <div className="third-team-content">
                      <div className="main-bg" />
                      <div className="hover-bg" />
                      <h5><a href={teamInfo[2].instagramLink} target="_blank" rel="noreferrer">{teamInfo[2].name}</a></h5>
                      <span>Frontend/Web3 Dev</span>
                    </div>
                  </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <AnimationOnScroll animateIn='animate__rollIn' duration={2} animateOnce={true}>
                  <div className="third-team-item text-center mb-30">
                    <div className="third-team-img">
                      <img src="assets/img/team/4.png" alt="JungleVerse lec nft" className="mx-auto"/>
                    </div>
                    <div className="third-team-content">
                      <div className="main-bg" />
                      <div className="hover-bg" />
                      <h5><a href={teamInfo[3].instagramLink} target="_blank" rel="noreferrer">{teamInfo[3].name}</a></h5>
                      <span>Metaverse/P2E Dev</span>
                    </div>
                  </div>
                  </AnimationOnScroll>
                </div>


            </div>
                          
            </div>
          </section>
  )
}

export default TeamArea