

import React, { useState } from 'react'

function range(start, end) {
  var ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}

const MintQuantity = ({ maxMint = 0, mintQuantity, setMintQuantity }) => {
  return (
    <div id="nftCount">
      <div className='mb-3'>
        <input type="text" value={mintQuantity} className='form-control nft-count'

        onKeyDown={()=>{
          setMintQuantity(0)
        }}

        onKeyUp={(e)=>{
          if(e.target.value != 0)setMintQuantity(e.target.value)
          else {setMintQuantity(1)}
        }}

        onChange={(e)=>{
          const _range = range(1, maxMint)
          const value = parseInt(e.target.value)
          if(_range.includes(value)){
            setMintQuantity(value)
          }
          else{
            setMintQuantity(1)
          }
        }}
        />
      </div>

      <div className=''>
        <button disabled type='button' className='max-nft d-block p-1 rounded ml-auto'>{maxMint} max</button>
      </div>
    </div>
  )
}

export default MintQuantity