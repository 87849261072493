import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'


const Benefits = () => {

  return (
    <section id="benefits" className='benefits-bg  py-5'>
        <AnimationOnScroll animateIn='animate__wobble' duration={4} animateOnce={true}>

            <div className="third-section-title text-center mb-2">
              <h2>Be<span>ne</span>fits</h2>
            </div>
    <div className='benefits-container'>

        <img src="./assets/img/benefits/5.png" alt="" className='benefit-img w-100 d-block'/>
        <img src="./assets/img/benefits/1.png" alt="lucky elephant nft benefits" className='benefits-coin d-block'/>
       
        
    </div>
    </AnimationOnScroll>
    </section>
  )
}

export default Benefits