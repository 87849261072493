import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/homes/Home';
import "animate.css/animate.min.css";
import { Mint } from './pages/Mint/Mint';
import Store from './pages/Store/Store';
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { w3mConnectors, w3mProvider , EthereumClient} from '@web3modal/ethereum'



function App() {

  const chains = [mainnet];

  // Wagmi client
  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId: "d94dff1fe1978bd4e2e71be729f28a49" }),
  ]);
  const wagmiClient = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({
      projectId: "d94dff1fe1978bd4e2e71be729f28a49",
      chains,
    }),
    publicClient,
  });

  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);
  return (

    <div className="App">

      <WagmiConfig config={wagmiClient}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/mint' element={<Mint />} />
            <Route path='/store' element={<Store />} />
          </Routes>
        </Router>
      </WagmiConfig>

      <Web3Modal
        projectId="d94dff1fe1978bd4e2e71be729f28a49"
        ethereumClient={ethereumClient}
      ></Web3Modal>    </div>
  );
}

export default App;
