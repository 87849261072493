import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const Jungleverse = () => {

    const jungleverse = <img src="./assets/img/images/Jungleverse.png" alt="" className='jungleverse-img w-100 d-block' />
    return (
      <section id="jungleverse" className='jungleverse-bg  py-5'>
          <AnimationOnScroll animateIn='animate__wobble' duration={4} animateOnce={true}>
  
              <div className="third-section-title text-center mb-2">
                <h2>Jun<span>gle</span>verse</h2>
              </div>
      <div className='jungleverse-container'>
  
          {jungleverse}
          
         
          
      </div>
      </AnimationOnScroll>
      </section>
    )
  }
  
export default Jungleverse