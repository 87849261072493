
import React from 'react'

 const MintHeader = () => {
  return (
    <div className='row flex-column gy-2'>
        <h3 className='mint-main-header'>Enter the Lucky Elephant's Club</h3>
        <h5 className='mint-sub-header'>Enter the amount of Lucky Elephant's you would like to purchase</h5>
    </div>
  )
}

export default MintHeader;