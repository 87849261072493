import React from 'react';

const NftCalendar = () => {
  return (
    <div className='mx-auto d-flex justify-content-center flex-column align-items-center'>
      {/* NFT Calendar Section */}
      <div className='text-end nft-calendar'>
        <h3 className='fst-italic fwt-bold d-inline-block mr-5'>As seen on</h3>
        <a href="https://nftcalendar.io/event/lucky-elephant-club/" target="_blank" rel="noopener noreferrer">
          <img src="assets/img/images/nft-calendar-transparent.png" alt="nft-calendar-logo" style={{ maxWidth: '150px', height: 'auto' }} />
        </a>
      </div>

      {/* Rarity Sniper and NFTDroops sections removed */}
    </div>
  );
}

export default NftCalendar;
