import React from 'react'
import Index from '../../components/index/indexthree/Index';

function Home() {

  return (
    <Index/>
  )
}

export default Home