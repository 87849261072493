import React from 'react'
import Footer from '../../footer/Footer';
import Slider from './Slider'
import Evolution from './Evolution'
import Roadmap from './Roadmap'
import Merch from './Merch'
import TeamArea from './TeamArea'
import Story from "./Story"
import Benefits from "./Benefits"
import NftCalendar from './NftCalendar';
import Raritysniper from './Raritysniper';
import NftDroops from './Nftdroops';
import Faq from './Faq';
import P2E from '../../P2E/P2E';
import Header from '../../header/Header';
import MintIsLive from '../../mint/MintIsLive';
import Jungleverse from './Jungleverse';

function Index() {
  return (
    <>
      {/* header-area */}
      <Header />

      {/* header-area-end */}
      {/* main-area */}
      <main>
        {/* slider-area */}
        <Slider />
        {/* slider-area-end */}
        <div className="bg-wrapper">
          {/* Our story */}
          <MintIsLive />
          <Story />
          {/* Our Story End */}

          {/* Jungleverse Area */}
          <Jungleverse />
          {/* Jungleverse Area-end */}



          {/* Evolution Area */}
          <Evolution />
          {/* Evolution Area-end */}

          <P2E />

          {/* Benefits */}
          <Benefits />
          {/* Benefits-area-end */}

          {/* Roadmap */}

          <Roadmap />
          {/* Roadmap-end */}
        </div>

        {/* Merch-area */}
        <Merch />
        {/* Merch-area-end */}

        <Faq />
        {/* team-area */}
        <div className='team-bg'>
          <TeamArea />
          {/* team-area-end */}

        <NftCalendar />
        <Raritysniper />
        <NftDroops />
        </div>
      </main>
      {/* main-area-end */}
      <Footer />
    </>

  )
}

export default Index