
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const MintIsLive = () => {
  return (
    <div style={{
        textAlign: "center",
        padding: 20,
    }}>
    <HashLink to="/mint" className="btn rotated-btn" style={{
        backgroundColor: "white",
        color: "#000"
    }}>OG Mint Is Live!</HashLink>
    </div>
  )
}

export default MintIsLive
