import React from 'react'

function SliderThree() {
  return (
	 <section className="third-banner-bg" id="home">
            <div className="container custom-container">                
                  <div className="third-banner-content text-center wow bounceInUp" data-wow-delay=".2s">
                    <h6>JungleVerse</h6>
                
                
              </div>
            </div>
          </section>
  )
}

export default SliderThree