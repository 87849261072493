function Accordion({ question, answer, selected, index, ...rest }) {
  return (
    <div
      className='border rounded py-4 p-3 mb-2 faq-hover'
      {...rest}
    >
      <div className='d-flex justify-content-between' role="button">
        <h5>{question}</h5>
        {selected === index ? '-' : '+'}
      </div>
      <div
        className={`faq-content ${
          selected === index ? 'faq-selected' : 'faq-unselected' }`} >
        <p>{answer}</p>
      </div>
    </div>
  );
}

export default Accordion;
