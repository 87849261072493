import React from 'react';

const RaritySniper = () => {
  return (
    <div className='text-center mt-3'>
      <a href="https://raritysniper.com/nft-drops-calendar" target="_blank" rel="noopener noreferrer">
        <img src="assets/img/images/rarity-sniper.png" alt="rarity-sniper-logo" style={{ maxWidth: '150px', height: 'auto' }} />
      </a>
    </div>
  );
};

export default RaritySniper;
