import React from 'react'
import {AnimationOnScroll} from "react-animation-on-scroll"


function Merch() {
  return (
    <section id="merch" className="donation-area donation-bg fix pt-65 pb-5">
      <div className="container custom-container">
        <div className="donation-wrap">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7">
              <div className="donation-content">
                <div className="third-title-style">
                <AnimationOnScroll animateIn='animate__slideInLeft' duration={2} animateOnce={true}>
                  <h2>STREET<span> WEAR </span>COLLECTIONS</h2>
                  </AnimationOnScroll>
                  <div className="inner">
                  <AnimationOnScroll animateIn='animate__slideInRight' duration={2} animateOnce={true}>
                    <h2>STAY DRIPPED OUT</h2>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn='animate__fadeIn' duration={3} animateOnce={true}>
                    <h6 className="vertical-title">donation</h6>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn='animate__fadeInBottomRight' duration={2} animateOnce={true}>
                    <p>EXPLORE AND SHOP OUR EXCLUSIVE COLLECTION.
                      10% OF EVERY SALE WILL BE DONATED TO WILDLIFE CONSERVATION NGO's.</p>
                      </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll animateIn='animate__fadeInLeft' duration={2} animateOnce={true}>
                  </AnimationOnScroll>
                </div>
                  <a href="https://theluckyclubshop.com" className="btn rotated-btn" target="_blank" rel="noopener noreferrer">Shop Now</a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <AnimationOnScroll animateIn='animate__rotateInUpRight' duration={2} animateOnce={true}>
              <div className="donation-img text-center">
                <img src="assets/img/images/wear.png" alt="lucky elephant club nft store or wears" />
              </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Merch