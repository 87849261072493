
import React from 'react'

const MintPriceAndSupply = ({mintPrice, maxSupply, mintedNfts}) => {
  return (
     <div className='priceAndSupplyContainer row mb-3 py-2'>
        <div className='col-4 '>
            <div className='mint-image-1'>
            <img src="./assets/img/team/2.png" alt="" className='h-100 w-100'/>
            </div>
        </div>
        <div className='col d-flex flex-column align-items-end gap-3'>
            <span>Price Of Each Lucky Elephant</span>
            <span className='price'><span className='gold'>{!isNaN(mintPrice)?mintPrice:'...'}</span> ETH Each</span>
            <span>{maxSupply - mintedNfts} remaining</span>
        </div>
    </div>
  )
}

export default MintPriceAndSupply