import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function Evolution() {
  return (
    
	<section className="third-about-area third-about-bg pt-120 pb-90">
            <div className="container custom-container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-0 order-lg-2">
                <AnimationOnScroll animateIn='animate__rotateInUpRight' duration={3} animateOnce={true}>
                  <div className="third-about-img text-right position-relative">
                    <img src="assets/img/images/333.png" className="main-img" alt="lucky elephant nft" />
                    <img src="assets/img/images/bkg_shadow.png" className="shadow" alt="" />
                  </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-6">
                  
                  <div className="third-about-content">
                    <div className="third-title-style">
                    <AnimationOnScroll animateIn='animate__slideInLeft' duration={2} animateOnce={true}>
                      <h2>Evo<span>lut</span>ion</h2>
                      </AnimationOnScroll>
                      <div className="inner">
                        <AnimationOnScroll animateIn='animate__slideInRight' duration={2} animateOnce={true}>
                        <h2>Know The Process</h2>
                        </AnimationOnScroll>
                        
                        <AnimationOnScroll animateIn='animate__fadeIn' duration={3}>
                        <h6 className="vertical-title">evolution</h6>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn='animate__fadeInBottomRight' duration={2} animateOnce={true}>

                        <p>Though the elephants could not be bitten and infected via saliva, blood, or others similar to that, their food source was running slow. The younger of the population was surviving well, whereas those that were older and struggling to find enough food and sustenance were slowly beginning to fade. Eventually, there was only one hope left…The youngest.</p>
                        </AnimationOnScroll>
                      </div>
                  <AnimationOnScroll animateIn='animate__fadeInLeft' duration={2} animateOnce={true}>

                      <a href="https://medium.com/@theluckyelephantclub/lucky-elephant-club-jungleverse-luckypaper-2-0-748b7aeeefce" className="btn rotated-btn">Read Our LuckyPaper 2.0</a>
                      </AnimationOnScroll>
                    </div>
                  </div>
          

                </div>
              </div>
              </div>
             
          </section>
  )
}

export default Evolution