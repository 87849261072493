
import React from 'react'
import ReactPlayer from 'react-player'

const P2E = () => {
  return (
    <section id="benefits" className='py-5' style={{backgroundColor: "black"}}>
         <div className="third-section-title text-center mb-2">
              <h2>P<span>2</span>E</h2>
            </div>
    <div>
        <ReactPlayer url={"https://www.youtube.com/embed/XGnqhteU1iQ"}  width="100%" height={"450px"} />
        
    </div>
    </section>
  )
}

export default P2E
