import React from 'react'
import {BeatLoader} from "react-spinners"
import { parseEther } from 'viem'


const MintButton = ({isConnected, mint, isMinting,mintPrice,mintQuantity}) => {
  return (
    <form form="nftCount">
    <div>
      {
       isConnected || mint?<button type="button" className='btn w-100' onClick={()=>{
        try{mint()}
        catch{}
      }}>{!isMinting?"Claim Nft(s)":
       <BeatLoader 
       color='#fff'
       size={10}
       />}
       </button>:
       <button type="button" className='btn w-100' disabled style={{cursor: "not-allowed"}}>Claim Nft(s)</button>

      }
    </div>
    </form>
  )
}

export default MintButton