import { useState } from 'react';
import Accordion from './Accordion';
const data = [
  {
    question: 'HOW DO I GET INTO THE LUCKY ELEPHANT CLUB?',
    answer: "Ready for the adventure? Be an early supporter and get OG'd Limited Spots available or make our Luckylist. Follow us on Twitter and join our Discord and make sure you don't miss the adventure.",
    id: 1,
  },
  {
    question: 'WHEN IS THE OFFICIAL LAUNCH DATE?',
    answer: 'Join our Discord community and stay prepared for when we announce our official Lucky Date.',
    id: 2,
  },
  {
    question: 'WHAT WILL BE THE PRICE?',
    answer: "Luckylist (Max 3 per wallet): 0.015 ETH - Public (max 5 per wallet): 0.045 ETH",
    id: 3,
  },
  {
    question: 'HOW DO I GET LUCKYLISTED?',
    answer: 'Be involved, get awarded or secure your spot by completing community challenges and competing in community events.',
    id: 4,
  },
  {
    question: 'WHAT ARE BENEFITS FOR MY NFT?',
    answer: 'Holders are entitled to 100% IP + commercial use rights, and their membership will offer exclusive access to future drops, VIP lounges at IRL events, and other IRL utilities',
    id: 5,
  },

];




function Faq() {
  const [selected, setSelected] = useState(null);
  const handleClick = (index) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  console.log('selected', selected);
  return (
    <section className="faq-bg py-5">
            <div className="third-section-title text-center mb-3">
              <h2>F<span>a</span>q</h2>
            </div>
      <div className='pt-8 mx-auto px-2' style={{
        maxWidth: 700
      }}>
        {data.map((faq, index) => (
          <Accordion
            question={faq.question}
            answer={faq.answer}
            selected={selected}
            index={index}
            onClick={() => handleClick(index)}
            key={index}
          />
        ))}
      </div>
    </section>
  );
}

export default Faq;
