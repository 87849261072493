

import React from 'react'

const MintJoinMessage = () => {
  return (
    <div>
      <h2 className='joinMessage'>join<br /><span className='gold'>thE <br />party</span></h2>
    </div>
  )
}

export  default MintJoinMessage