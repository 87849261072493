import React from 'react'
import {AnimationOnScroll} from "react-animation-on-scroll"

function Roadmap() {
  return (
    <section id="roadmap" className="my-match-area my-match-bg pb-120">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <div className="third-section-title text-center mb-2">
              <h2>ro<span>adm</span>ap</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="my-match-wrap">
              <AnimationOnScroll animateIn='animate__fadeInLeft' duration="3" animateOnce={true}>
              <div className="my-match-box-wrap wow fadeInDown" data-wow-delay=".2s">
    
                <img src="assets/img/bg/roadmap_bg.png" alt="" className="match-box-bg" />
                <ul>
                  <li>
                    <div className="roadmap">
                      <div className='pl-5'>
                        <h3>Phase 1</h3>
                        <span>Revival</span>
                      </div>
                      <div>
              <AnimationOnScroll animateIn='animate__zoomIn' duration={2} delay={2} animateOnce={true}>

                        <img src="assets/img/images/roadmap-img.png" alt=""/>
                </AnimationOnScroll>

                      </div>
                    </div>
                  </li>
                  <li  className="ml-auto">
                      <p className='roadmap-content'>Gain access to the Lucky Jungle, where we host exclusive airdrops, project giveaways, live events, and community gatherings to nuture growth and talent alike.</p>
                    
                  </li>
                </ul>
              </div>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn='animate__fadeInLeft' duration="3" animateOnce={true}>
              <div className="my-match-box-wrap wow fadeInDown" data-wow-delay=".4s">
                <img src="assets/img/bg/roadmap_bg.png" alt="" className="match-box-bg" />
                <ul>
                  <li>
                    <div className="roadmap">
                      <div className='pl-5'>
                        <h3>Phase 2</h3>
                        <span>Tuck Your Phants</span>
                      </div>
                      <div>
                      <AnimationOnScroll animateIn='animate__zoomIn' duration={2} delay={2} animateOnce={true}>
                        <img src="assets/img/images/roadmap-img.png" alt=""/>
                        </AnimationOnScroll>
                      </div>
                    </div>
                  </li>
                  <li  className="ml-auto">
                   <p className='roadmap-content'>Our Team will partner with top talent creatives and designers to offer holders exclusives access to physical merchandise &amp; collectibles </p>
                  </li>
                </ul>
              </div>
</AnimationOnScroll>

<AnimationOnScroll animateIn='animate__fadeInLeft' duration="3" animateOnce={true}>
              <div className="my-match-box-wrap wow fadeInDown" data-wow-delay=".4s">
                <img src="assets/img/bg/roadmap_bg.png" alt="" className="match-box-bg" />
                <ul>
                  <li>
                    <div className="roadmap">
                      <div className='pl-5'>
                        <h3>Phase 3</h3>
                        <span>Water In The Holes</span>
                      </div>
                      <div>
                      <AnimationOnScroll animateIn='animate__zoomIn' duration={2} delay={2} animateOnce={true}>
                        <img src="assets/img/images/roadmap-img.png" alt=""/>
                        </AnimationOnScroll>
                      </div>
                    </div>
                  </li>
                  <li className="ml-auto">
                   <p className='roadmap-content'>A mysterious watering hole emerges from the ground. Every month, it randomly spurts an object for the lucky elephants to collect.</p>
                  </li>
                </ul>
              </div>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn='animate__fadeInLeft' duration="3" animateOnce={true}>
              <div className="my-match-box-wrap wow fadeInDown" data-wow-delay=".6s">
                <img src="assets/img/bg/roadmap_bg.png" alt="" className="match-box-bg" />
                <ul>
                  <li>
                    <div className="roadmap">
                      <div className='pl-5'>
                        <h3>Stampede</h3>
                        <span>V1.5</span>
                      </div>
                      <div>
                      <AnimationOnScroll animateIn='animate__zoomIn' duration={2} delay={2} animateOnce={true}>
                        <img src="assets/img/images/roadmap-img.png" alt=""/>
                        </AnimationOnScroll>
                      </div>
                    </div>
                  </li>
                  <li  className="ml-auto ">
                   <p className='roadmap-content'>Nfts and eth giveaway moneybag <br/>
                   Exclusive merchandise LEC for our Streetwear Designs launch. Free Airdrop and Giveaway for 500 proactive LEC Holders.<br/>

                   </p>
                  </li>
                </ul>
              </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Roadmap