import React from 'react'
import { LINKS } from '../../../Model/home'
import { AnimationOnScroll } from 'react-animation-on-scroll'


const Story = () => {
    return (
        <AnimationOnScroll animateIn='animate__slideInUp' duration={3} offset={350} animateOnce={true}>
        <section  id="story" className='our-story py-5'>
        <div className='d-flex align-items-center  flex-column gap-3'>
                        <div className="third-section-title text-center mb-2">
              <h2>our <span>l</span>ore</h2>
            </div>
            <p className="w-75 text-center py-3">
                The world had been rampaged by disease, killing off millions as the world fought an infection that they were likely to never come back from. From humans to trees, the infection was ruthless, spoiling the soil and waters which blackened with ease, and with the new world, came a new species. Those that were previously human and had been taken hold of by the disease transformed, their skin paling and thickening as they were reprogrammed to be hunting machines. What we now call the Eopma. Jungles had already been horribly ruined by the humans, even before they became restless and infected… Jungle animals had gone rabid with the disease, trees were withering and falling, and at the center of it all? The singular species that by some miracle could not be infected. The elephants...
            </p>


            <AnimationOnScroll animateIn='animate__fadeInLeft' duration={2} animateOnce={true}>

    <a href={LINKS.luckyDiscord} className="btn rotated-btn">Join Our Discord</a>

</AnimationOnScroll>
        </div>
        </section>
        </AnimationOnScroll>
    )
}

export default Story