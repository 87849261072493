

import React from 'react'

const MintTotalPrice = ({mintPrice, mintQuantity}) => {
  return (
    <div className='d-flex justify-content-between py-4 border-top border-bottom my-1'>
        <span>Total</span>
        <div>
            <span className='d-block price'>{!isNaN(mintPrice * parseInt(mintQuantity))?mintPrice * parseInt(mintQuantity):'...'} ETH</span>
            
            <span className="d-block text-light">excluding gas fee</span>
        </div>
    </div>
  )
}

export default MintTotalPrice