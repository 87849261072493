import React, { useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";
import { LINKS } from '../../Model/home';
import { Web3Button } from "@web3modal/react";


function Header() {

  useEffect(() => {


    //Mobile Nav Hide Show
    if ($('.mobile-menu').length) {

      var mobileMenuContent = $('.push-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);




      $('.menu-backdrop, .mobile-menu .close-btn').click(() => {
        $('body').removeClass('mobile-menu-visible');
      })


      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });
    }


  }, []);
  useEffect(() => {
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');

      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });

  }, [])
  return (

    <header className="third-header-bg">
      <ScrollToTop smooth top="500" color="#000" />
      <div className="bg" />
      <div className="container custom-container">
        <div className="header-top-area t-header-top-area d-none d-lg-block">
          <div className="row">
            <div className="col-sm-6">
              <div className="header-top-social">
                <ul>
                  <li>Follow</li>
                  <li><a href={LINKS.luckyTwitter}><i className="fab fa-twitter" /></a></li>
                  <li><a href={LINKS.luckyDiscord}><i className="fab fa-discord" /></a></li>
                  <li><a href={LINKS.luckyInstagram}><i className="fab fa-instagram" /></a></li>

                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="header-top-login">
                <ul>
                  <Web3Button />

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
              <div className="main-menu  menu-style-two">
                <nav>
                  <div className="logo d-block d-lg-none">
                    <img src="assets/img/logo/m_lc_logo.png" alt="Lucky elephants club logo" />
                  </div>
                  <div className="navbar-wrap d-none d-lg-flex">

                    <ul className="left">
                      <li><HashLink to="/#home"
                        smooth={true}>Home</HashLink></li>
                      <li><HashLink to="/#story"

                        smooth={true}


                      >Story</HashLink></li>
                      <li>

                        <HashLink to="/#benefits"

                          smooth={true}


                        >Benefits</HashLink></li>
                      <li><HashLink to="/#roadmap"

                        smooth={true}


                      >Roadmap</HashLink></li>
                      <li><HashLink to="/mint"

                        smooth={true}
                        style={
                          {
                            color: "gold"
                          }
                        }

                      >Mint</HashLink></li>
                    </ul>
                    <div className="logo">

                      <img src="assets/img/logo/lc_logo.png" alt="logo for lucky elephant's club nft" />
                    </div>
                    <ul className="right">
                      <li><a href="https://medium.com/@theluckyelephantclub/lucky-elephant-club-jungleverse-luckypaper-2-0-748b7aeeefce"
                        target="_blank" rel="noopener noreferrer">LuckyPaper</a></li>
                      <li>
                        <a href="https://theluckyclubshop.com" target="_blank" rel="noopener noreferrer">Merch</a></li>
                        <li><a href="https://blokzoo.com" target="_blank" rel="noopener noreferrer">BlokZoo</a></li> {/* Corrected line */}

                      <li><HashLink to="/#team"

                        smooth={true}

                      >Team</HashLink></li>

                    </ul>

                  </div>
                </nav>
              </div>

              <div className="mobile-menu-wrap main-menu d-block d-lg-none">
                <nav>
                  <div className="navbar-wrap push-menu">
                    <ul className="navigation">
                      <li><HashLink to="/#home"

                        smooth={true}


                      >Home</HashLink></li>
                      <li><HashLink to="/#story"

                        smooth={true}


                      >Story</HashLink></li>
                      <li><HashLink to="/#benefits"

                        smooth={true}


                      >Benefits</HashLink></li>
                      <li><HashLink to="/#roadmap"
                        smooth={true}
                      >Roadmap</HashLink></li>

                      <li><a href="https://medium.com/@theluckyelephantclub/lucky-elephant-club-jungleverse-luckypaper-2-0-748b7aeeefce" target="_blank" rel="noopener noreferrer">LuckyPaper</a></li>
                      <li>
                        <a href="https://theluckyclubshop.com" target="_blank" rel="noopener noreferrer">Merch</a></li>
                      <li><HashLink to="/"

                        smooth={true}
                      >Blokzoo</HashLink></li>
                      <li><HashLink to="/"

                        smooth={true}

                      >Team</HashLink></li>
                      <li style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}><Web3Button /></li>

                    </ul>
                  </div>
                </nav>
              </div>
              {/* mobile-menu           */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn"><i className="fas fa-times" /></div>
                  <div className="nav-logo">
                    <img src="assets/img/logo/m_lc_logo.png" alt="Lucky elephant club jungleverse logo" title='true' />
                  </div>
                  <div className="menu-outer">

                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a href={LINKS.luckyTwitter}><i className="fab fa-twitter" /></a></li>
                      <li><a href={LINKS.luckyDiscord}><i className="fab fa-discord" /></a></li>
                      <li><a href={LINKS.luckyInstagram}><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom-bg"></div>
    </header>
  )
}

export default Header
